"use client"; // Mark this component as a Client Component

import Link from "next/link";
import { Button } from "@/components/ui/button";
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "@/components/ui/card";
import { Input } from "@/components/ui/input";
import { Label } from "@/components/ui/label";
import { SiteHeader } from "@/components/site-header";
import { useState } from "react";
import { handleLogin } from "@/app/login/actions"; // Import server-side action
import { Alert, AlertDescription, AlertTitle } from "@/components/ui/alert";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { useRouter } from "next/navigation";

export const runtime = "edge"; // Add this to ensure it runs in the Edge runtime

export default function Login() {
  const [error, setError] = useState<string | null>(null); // Error state to display message
  const [pending, setPending] = useState(false); // Manual pending state
  const router = useRouter();

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    setPending(true);
    setError(null);

    const formData = new FormData(e.target as HTMLFormElement);
    const { errorMessage, success } = await handleLogin(formData);

    setPending(false); // Always reset pending state

    errorMessage ? setError(errorMessage) : success && router.push("/dashboard");
  };

  return (
    <>
      <SiteHeader />
      <div className="flex h-screen w-screen items-center justify-center">
        <Card className="mx-auto max-w-sm">
          <CardHeader>
            <CardTitle className="text-2xl">Login</CardTitle>
            <CardDescription>Enter your email below to login to your account</CardDescription>
          </CardHeader>
          <CardContent>
            <form onSubmit={handleSubmit}>
              {error && (
                <Alert className="mb-4 bg-red-500">
                  <AlertTitle>Error</AlertTitle>
                  <AlertDescription>{error}</AlertDescription>
                </Alert>
              )}
              <div className="grid gap-4">
                <div className="grid gap-2">
                  <Label htmlFor="email">Email</Label>
                  <Input id="email" name="email" type="email" placeholder="m@example.com" required />
                </div>
                <div className="grid gap-2">
                  <div className="flex items-center">
                    <Label htmlFor="password">Password</Label>
                    <Link href="#" className="ml-auto inline-block text-sm underline">
                      Forgot your password?
                    </Link>
                  </div>
                  <Input id="password" name="password" type="password" required />
                </div>
                <Button type="submit" className="w-full" disabled={pending}>
                  {pending ? "Signing In..." : "Login"}
                </Button>
                <Popover>
                  <PopoverTrigger asChild>
                    <Button variant="outline" className="w-full">
                      Configure Oauth provider
                    </Button>
                  </PopoverTrigger>
                  <PopoverContent className="text-sm">
                    <div>
                      Session management and authentication are already configured for login using the Lucia library. You can add
                      additional auth providers at no cost.
                    </div>
                    <div className="mt-4">
                      <Link href="https://lucia-auth.com/tutorials/github-oauth/nextjs-app" target="_blank" className="underline">
                        Learn more here.
                      </Link>
                    </div>
                  </PopoverContent>
                </Popover>
              </div>
              <div className="mt-4 text-center text-sm">
                Don&apos;t have an account?{" "}
                <Link href="/register" className="underline">
                  Sign up
                </Link>
              </div>
            </form>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
